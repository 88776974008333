<template>
    <uw-content title="出入库明细">

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <ims-warehouse-select  v-model="search.warehoser_id" @onChange="TableSearch()" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 动作 -->
                    <vxe-column width="120" title="动作">
                        <template #default="{ row }">
                            {{ row.action == 1 ? '入库' : '出库' }}
                            <el-tag effect="dark" size="mini" v-if="!row.state" type="danger" :style="{ marginLeft: '10px' }">已作废</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 类型 -->
                    <vxe-column width="100" title="类型">
                        <template #default="{ row }">
                            <span v-if="row.model == 'init'">初始化入库</span>
                            <span v-if="row.model == 'other'">其它</span>
                            <span v-if="row.model == 'ims_warehouse_move'">库存调拨</span>
                            <span v-if="row.model == 'ims_product_back'">退货订单</span>
                            <span v-if="row.model == 'ims_product_order'">采购订单入库</span>
                            <span v-if="row.model == 'ims_product_invalid'">产品报废</span>
                            <span v-if="row.model == 'ims_product_apply'">产品申领</span>
                        </template>
                    </vxe-column>

                    <!-- 关联单据 -->
                    <vxe-column width="140" title="关联单据">
                        <template #default="{ row }">
                            <span class="el-span-primary" v-if="row.model == 'ims_warehouse_move'" @click="$refs.warehouseMoveDrawer.Open(row.model_id)">{{ row.model_number }}</span>
                            <span class="el-span-primary" v-if="row.model == 'ims_product_order'" @click="$refs.productOrderDrawer.Open(row.model_id)">{{ row.model_number }}</span>
                            <span class="el-span-primary" v-if="row.model == 'ims_product_back'" @click="$refs.productBackDrawer.Open(row.model_id)">{{ row.model_number }}</span>
                            <span class="el-span-primary" v-if="row.model == 'ims_product_invalid'" @click="$refs.productInvalidDrawer.Open(row.model_id)">{{ row.model_number }}</span>
                            <span class="el-span-primary" v-if="row.model == 'ims_product_apply'" @click="$refs.productApplyDrawer.Open(row.model_id)">{{ row.model_number }}</span>
                        </template>
                    </vxe-column>

                    <!-- 产品名称 -->
                    <vxe-column width="140" title="产品名称">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.productDrawer.Open(row.product.id)">{{ row.product.name }}</span>
                        </template>
                    </vxe-column>

                    <!-- 供应商 -->
                    <vxe-column width="140" title="供应商">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.supplierDrawer.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                        </template>
                    </vxe-column>

                    <vxe-column min-width="80" field="product.type.name" title="产品类型"></vxe-column>
                    <vxe-column min-width="80" field="product.unit" title="产品单位"></vxe-column>
                    <vxe-column min-width="80" field="product.brand" title="产品品牌"></vxe-column>
                    <vxe-column min-width="80" field="warehouse.name" title="关联仓库"></vxe-column>
                    <vxe-column min-width="80" field="num" title="数量"></vxe-column>
                    <vxe-column min-width="120" field="describe" title="备注"></vxe-column>
                    <vxe-column width="80" field="created_user.name" title="负责人"></vxe-column>
                    <vxe-column width="140" field="created_at" title="创建时间"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-warehouse-move-drawer ref="warehouseMoveDrawer" @onChange="TableSearch" />
        <ims-product-order-drawer ref="productOrderDrawer" @onChange="TableSearch" />
        <ims-product-back-drawer ref="productBackDrawer" @onChange="TableSearch" />
        <ims-product-invalid-drawer ref="productInvalidDrawer" @onChange="TableSearch" />
        <ims-product-apply-drawer ref="productApplyDrawer" @onChange="TableSearch" />
        <ims-product-drawer ref="productDrawer" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                warehoser_id: null,
                number: null,
                action: 0
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 数据查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/warehouse-log/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user', 'product', 'warehouse', 'model_number']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }

}
</script>